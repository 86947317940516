/* You can add global styles to this file, and also import other style files. */

/*
Part of solution to keep footer on the bottom of the page.
Also see other part of solution in app.component.css.
See: https://cssreset.com/how-to-keep-footer-at-bottom-of-page-with-css/
*/
html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
}

/*
Kendo UI overrides
*/

.k-button.k-primary {
    background-color: #006efe;
}

.k-button.k-primary.k-state-focused, .k-button.k-primary:focus {
    box-shadow: 0px 0px 0px 2px rgba(0,123,255,0.5); /* Originally: box-shadow: 0px 0px 0px 3px rgba(0,123,255,0.5); */
}

.k-widget.form-control .k-input
{
    height: 1.7rem !important;
    font-family: "Segoe UI";
    font-size: 14px;
}

.k-dropdown .k-dropdown-wrap {
    background-color: white;
    border-width: 2px;
    border-radius: 0px;
    border-color: #D9D9D9;
}

.k-list-container {
    font-family: "Segoe UI";
    font-size: 14px;
}

.k-list .k-item {
    padding: 3px 1rem;
}

.k-list-optionlabel, .k-list .k-item {
    padding: 3px 1rem;
    font-size: 10pt;
}

.k-button {
    line-height: 18px;
    border-radius: 0px;
    align-content: center;
    font-family: "Segoe UI";
    font-size: 12px;
    margin: 0px 10px !important;
    border: 1px solid transparent;
    min-width: 80px;
    padding: 5px 10px;
    cursor: pointer;
}

kendo-upload {
    max-width: 300px;
}

.k-upload-status .k-button {
    margin: 0px 0px !important;
    min-width: 0px;
}

.k-upload .k-upload-files .k-file-name {
    font-size: 10pt;
}

.k-upload .k-upload-files .k-upload-status {
    top: 0.50rem;
    right: .25rem;
}

.k-upload .k-upload-files .k-file-extension {
    font-size: 6pt;
}

.k-upload .k-upload-files .k-file-name-size-wrapper {
    margin-right: calc(16px + .70rem);
}

.k-upload .k-dropzone {
    margin: 5px 0 5px 0;
}

.k-upload .k-dropzone em {
    white-space: normal;
    font-size: 10pt;
}

/*
ng-bootstrap overrides
*/

/*
Tooltips have center justification normally. Looks better - I think - with left justification.
https://ng-bootstrap.github.io/#/components/tooltip
*/
.tooltip-inner {
    text-align: left;
    width: 300px;
    max-width: 300px;
}

/*
Global styles to be shared across components.
*/

hr {
    width: 100%;
    height: 4px;
    padding: 0px;
    margin: 0px;
    border: none;
    background: #d2d2d2;
}

.tip-icon {
    vertical-align: top;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding-left: 3px;
}

.required {
    color: #dc3545;
    padding-left: 3px;
    vertical-align: top;
}

/* Following styles used on several pages (ex: bank, tax, potentially others) - and are centralized here rather than duplicating them on each page. */

/* Used with bootstrap row class in some components. */
.container-input {
    border-left: 1px solid #D0D2D3;
    border-right: 1px solid #D0D2D3;
    padding: 16px 35px 0px 50px;
}

/* BUG8198967 fix - overlapping at 400% */
@media (max-width: 399px) {
    .container-input{
        padding: 0px;
    }
}

.container-input:last-child {
    padding-bottom: 16px !important;
}

.section-title-footer {
    background-color: #DDDDDD;
    border: 1px solid #C4C4C4;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 26px;
    font-family: 'Segoe UI Light';
    color: #515050;
}

.section-tip-text {
    font-size: 10pt;
    margin: 10px 0px 0px 0px !important;
    padding-right: 25px;
}

.section-cancel {
    display:flex;
    justify-content:flex-end;
}
